var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "row-item-list" },
    _vm._l(_vm.itemList, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "row-item",
          on: {
            click: function ($event) {
              return _vm.clickItem(item)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "img-box" },
            [
              _c("yqt-image", {
                staticClass: "img",
                attrs: {
                  src: _vm._f("ossProcess")(
                    _vm._f("setHttps")(item.base_pic),
                    "style/w_480_h_auto"
                  ),
                  fit: "cover",
                  lazyload: "",
                  alt: "item pic",
                },
              }),
            ],
            1
          ),
          _c("h3", { staticClass: "item-name" }, [
            item.source_type
              ? _c("span", { staticClass: "sourceName" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getSourceTypes(item.source_type)) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v("\n      " + _vm._s(item.item_name) + "\n    "),
          ]),
          _c("h4", { staticClass: "item-intro" }, [
            _vm._v(_vm._s(item.item_desc)),
          ]),
          _vm.salesStatus()
            ? _c("p", { staticClass: "sales" }, [
                _c("span", [_vm._v("已售")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getSales(item.id, item.cost_price))),
                ]),
              ])
            : _vm._e(),
          _c("div", [
            item.display_type === 3 && item.installment_plans
              ? _c("div", { staticClass: "lusse" }, [
                  _c("span", { staticClass: "plans money" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.installment_plans.slice(-1)[0].avg_price) +
                        "\n        "
                    ),
                  ]),
                  _c("span", { staticClass: "plans" }, [_vm._v("元")]),
                  _vm._v("/月起\n      "),
                ])
              : _vm._e(),
            _c("div", { staticClass: "priceWithBtn" }, [
              _vm.businessType == 2
                ? _c("p", { staticClass: "item-price" }, [
                    _vm.type === "normal" && _vm.price_status == 1
                      ? _c(
                          "span",
                          {
                            staticClass: "price",
                            class: item.installment_plans
                              ? "original-price"
                              : "",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.priceShow(
                                    item.display_type,
                                    item.price,
                                    item.max_integral_percentage
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.type === "group" && _vm.price_status == 1
                      ? _c(
                          "span",
                          {
                            staticClass: "price",
                            class: item.installment_plans
                              ? "original-price"
                              : "",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.priceShow(
                                    item.display_type,
                                    item.group_buy_price
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.type === "seconds" && _vm.price_status == 1
                      ? _c(
                          "span",
                          {
                            staticClass: "price",
                            class: item.installment_plans
                              ? "original-price"
                              : "",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.priceShow(
                                    item.display_type,
                                    item.limit_buy_price,
                                    item.max_integral_percentage
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.mall_price && _vm.mall_price_status == 1
                      ? _c("span", { staticClass: "old-price" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.priceShow(
                                  item.display_type,
                                  item.mall_price,
                                  item.max_integral_percentage
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _c(
                    "p",
                    {
                      staticClass: "item-price",
                      class: item.installment_plans ? "original-price" : "",
                    },
                    [
                      _vm.type === "normal" && _vm.price_status == 1
                        ? _c(
                            "span",
                            {
                              staticClass: "price",
                              class: item.installment_plans
                                ? "original-price"
                                : "",
                            },
                            [
                              _c("span", { staticClass: "sign" }, [
                                _vm._v("¥"),
                              ]),
                              _c("span", { staticClass: "num" }, [
                                _vm._v(_vm._s(item.price)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "group" && _vm.price_status == 1
                        ? _c(
                            "span",
                            {
                              staticClass: "price",
                              class: item.installment_plans
                                ? "original-price"
                                : "",
                            },
                            [
                              _c("span", { staticClass: "sign" }, [
                                _vm._v("¥"),
                              ]),
                              _c("span", { staticClass: "num" }, [
                                _vm._v(_vm._s(item.group_buy_price)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "seconds" && _vm.price_status == 1
                        ? _c(
                            "span",
                            {
                              staticClass: "price",
                              class: item.installment_plans
                                ? "original-price"
                                : "",
                            },
                            [
                              _c("span", { staticClass: "sign" }, [
                                _vm._v("¥"),
                              ]),
                              _c("span", { staticClass: "num" }, [
                                _vm._v(_vm._s(item.limit_buy_price)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      item.mall_price && _vm.mall_price_status == 1
                        ? _c("span", { staticClass: "old-price" }, [
                            _vm._v("¥" + _vm._s(item.mall_price)),
                          ])
                        : _vm._e(),
                    ]
                  ),
              _vm.type === "normal" && _vm.show_only !== 1
                ? _c("span", { staticClass: "btn buy-btn" }, [_vm._v("购买")])
                : _vm._e(),
              _vm.type === "seconds"
                ? _c("span", { staticClass: "btn buy-btn" }, [_vm._v("抢购")])
                : _vm._e(),
              _vm.type === "group"
                ? _c("span", { staticClass: "btn buy-btn" }, [_vm._v("参团")])
                : _vm._e(),
            ]),
          ]),
          _vm.tripartite_type === "VUE_APP_ZJG" && item.display_type !== 1
            ? _c("div", { staticClass: "zjg-max" }, [
                _vm._v(
                  "\n      积分最高抵扣" +
                    _vm._s(item.max_integral_percentage) +
                    "%\n    "
                ),
              ])
            : _vm._e(),
          _vm.type === "group"
            ? _c(
                "div",
                { staticClass: "group-num-wrap" },
                [
                  _c(
                    "yd-badge",
                    { attrs: { bgcolor: "#FE552E", color: "#FFF" } },
                    [
                      _c("span", { staticClass: "group-num" }, [
                        _vm._v(_vm._s(item.people_limit) + "人成团"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <ul class="row-item-list">
    <li
      v-for="(item, index) in itemList"
      :key="index"
      class="row-item"
      @click="clickItem(item)"
    >
      <div class="img-box">
        <yqt-image
          class="img"
          :src="item.base_pic | setHttps | ossProcess('style/w_480_h_auto')"
          fit="cover"
          lazyload
          alt="item pic"
        />
      </div>
      <h3 class="item-name">
        <span class="sourceName" v-if="item.source_type">
          {{ getSourceTypes(item.source_type) }}
        </span>
        {{ item.item_name }}
      </h3>
      <h4 class="item-intro">{{ item.item_desc }}</h4>
      <p class="sales" v-if="salesStatus()">
        <span>已售</span>
        <span>{{ getSales(item.id, item.cost_price) }}</span>
      </p>
      <div>
        <div
          v-if="item.display_type === 3 && item.installment_plans"
          class="lusse"
        >
          <span class="plans money">
            {{ item.installment_plans.slice(-1)[0].avg_price }}
          </span>
          <span class="plans">元</span>/月起
        </div>
        <div class="priceWithBtn">
          <p v-if="businessType == 2" class="item-price">
            <span
              v-if="type === 'normal' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              {{
                priceShow(
                  item.display_type,
                  item.price,
                  item.max_integral_percentage
                )
              }}
            </span>
            <span
              v-if="type === 'group' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              {{ priceShow(item.display_type, item.group_buy_price) }}
            </span>
            <span
              v-if="type === 'seconds' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              {{
                priceShow(
                  item.display_type,
                  item.limit_buy_price,
                  item.max_integral_percentage
                )
              }}
            </span>
            <span
              v-if="item.mall_price && mall_price_status == 1"
              class="old-price"
            >
              {{
                priceShow(
                  item.display_type,
                  item.mall_price,
                  item.max_integral_percentage
                )
              }}
            </span>
          </p>
          <p
            v-else
            class="item-price"
            :class="item.installment_plans ? 'original-price' : ''"
          >
            <span
              v-if="type === 'normal' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              <span class="sign">&yen;</span>
              <span class="num">{{ item.price }}</span>
            </span>
            <span
              v-if="type === 'group' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              <span class="sign">&yen;</span>
              <span class="num">{{ item.group_buy_price }}</span>
            </span>
            <span
              v-if="type === 'seconds' && price_status == 1"
              class="price"
              :class="item.installment_plans ? 'original-price' : ''"
            >
              <span class="sign">&yen;</span>
              <span class="num">{{ item.limit_buy_price }}</span>
            </span>
            <span
              v-if="item.mall_price && mall_price_status == 1"
              class="old-price"
              >&yen;{{ item.mall_price }}</span
            >
          </p>

          <span v-if="type === 'normal' && show_only !== 1" class="btn buy-btn"
            >购买</span
          >
          <span v-if="type === 'seconds'" class="btn buy-btn">抢购</span>
          <span v-if="type === 'group'" class="btn buy-btn">参团</span>
        </div>
      </div>

      <div
        v-if="tripartite_type === 'VUE_APP_ZJG' && item.display_type !== 1"
        class="zjg-max"
      >
        积分最高抵扣{{ item.max_integral_percentage }}%
      </div>

      <!-- /成团数 -->
      <div v-if="type === 'group'" class="group-num-wrap">
        <yd-badge bgcolor="#FE552E" color="#FFF">
          <span class="group-num">{{ item.people_limit }}人成团</span>
        </yd-badge>
      </div>
    </li>
  </ul>
</template>

<script>
import { getSales, priceShow } from "@/utils/goodsUtil";
import { mapState } from "vuex";
import { orderMixin } from "@/mixins/orderMixin.js";
import YqtImage from "@/components/image/Image";

export default {
  name: "RowItemList",
  components: {
    YqtImage,
  },
  props: {
    itemList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "normal",
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  mixins: [orderMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      bid: (state) => state.businessInfo.id, //商城id
      show_only: (state) => state.businessInfo.mall_display_only, // 商城展示状态
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      showWay: (state) => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
      tripartite_type: (state) => state.tripartite_type,
    }),
  },
  methods: {
    //处理已售
    getSales,

    //已售显隐
    salesStatus() {
      if (this.bid === 1131 || this.bid === 1134) {
        return true;
      } else {
        return false;
      }
      //return true;
    },

    // 点击商品
    clickItem(item) {
      this.$emit("click-item", item);
    },

    // 积分3.0显示
    priceShow,
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.row-item-list {
  .row-item {
    position: relative;
    // .wh(100%, 2.2rem);
    width: 100%;
    min-height: 2.2rem;
    padding-top: 0.24rem;
    padding-left: 2.4rem;
    background-color: #fff;
    box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.08rem;
    overflow: hidden;
    z-index: 1;

    .img-box {
      .ct;
      left: 0;
      .wh(2.2rem, 100%);
      background-color: #faf8fb;
      z-index: 2;

      .img {
        .wh(100%, 100%);
      }
    }

    .sales {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0.1rem 0;
      padding-right: 0.1rem;
    }

    .item-name {
      max-width: 100%;
      .sc(@fontsize-medium, rgba(0, 0, 0, 0.87));
      line-height: 0.4rem;
      word-break: break-all;
      word-wrap: break-word;
      .ellipsis(2);

      .sourceName {
        position: relative;
        top: -0.02rem;
        display: inline-block;
        height: 0.36rem;
        line-height: 0.36rem;
        padding: 0 0.15rem;
        border-radius: 0.18rem;
        background-color: var(--main-color);
        color: #ffffff;
        font-size: 0.18rem;
      }
    }

    .item-intro {
      .ellipsis(2);
      .sc(@fontsize-small, @color-light-grey);
      height: 0.65rem;
      line-height: 0.34rem;
    }

    .priceWithBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.1rem;

      .item-price {
        /* position: absolute;
		  left: 2.4rem;
		  bottom: 0.2rem; */

        .original-price {
          color: #919193 !important;
          font-size: 0.16rem;
        }

        .price {
          color: @color-dark-grey;
          line-height: 0.28rem;
          font-weight: bold;

          .sign {
            font-size: @fontsize-small;
          }

          .num {
            font-size: @fontsize-large;
          }
        }

        .old-price {
          display: inline-block;
          margin-left: 0.08rem;
          .sc(0.16rem, rgba(0, 0, 0, 0.38));
          line-height: 0.2rem;
          font-weight: bold;
          text-decoration: line-through;
        }
      }

      .buy-btn {
        /* position: absolute;
		  right: 0;
		  bottom: 0.2rem; */
        .wh(0.9rem, 0.48rem);
        padding-left: 0.26rem;
        padding-top: 0.08rem;
        .sc(@fontsize-small, #fff);
        line-height: 0.34rem;
        font-weight: 500;
        white-space: nowrap;
        background-color: var(--main-color);
        border-radius: 2rem 0px 0px 2rem;
        z-index: 2;
      }
    }

    .lusse {
      font-size: 0.2rem;
      margin-bottom: 0.1rem;
      color: #000000;

      .plans {
        color: #fe5d1c;
      }

      .money {
        font-size: 0.4rem;
      }
    }

    .zjg-max {
      color: var(--main-color);
      font-size: 0.2rem;
      margin-bottom: 0.1rem;
    }

    .group-num-wrap {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      display: flex;
      align-items: center;
      z-index: 2;

      .group-num {
        .sc(0.22rem, #fff);
        line-height: 0.32rem;
        white-space: nowrap;
      }
    }

    & + .row-item {
      margin-top: 0.3rem;
    }
  }
}
</style>

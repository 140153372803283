<template>
  <div id="search" class="flex-center">
    <search-box></search-box>
  </div>
</template>

<script>
import SearchBox from "@/components/search/SearchBox";

export default {
  name: 'Search',
  components: {
    SearchBox
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/mixin.less";

#search {
  .wh(100%, 1.00rem);
  padding: 0 .30rem;
}
</style>
